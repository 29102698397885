
import Vue from 'vue'
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import moment from 'moment';

import { Kokote } from '@/entities/devices/Kokote';

@Component
export default class StatusForm extends Vue {
  @Prop({ required: true })
  device!: Kokote;

  /**
   * Format uptime date
   */
  formatUptime(uptime: Date) {
    return moment(uptime).fromNow(true);
  }

  /**
   * Format cpu usage
   */
  formatCPU(cpu_usage: String) {
    var load = cpu_usage.split(' ');
    return load[0] + " " + load[1] + " " + load[2];
  }

    /**
   * Format cpu usage
   */
  formatMemory(memory_usage: String) {

    let total_memory_num = 1;
    let free_memory_num = 0;
    var use_memory = "";

    var total_memory = memory_usage.match(/MemTotal:\s+(\d+) kB/);
    if (total_memory) {
      total_memory_num = parseInt(total_memory[0].replace(/MemTotal:\s+/,"").slice(0,-3));
    }

    var free_memory = memory_usage.match(/MemFree:\s+(\d+) kB/);
    if (free_memory) {
      free_memory_num = parseInt(free_memory[0].replace(/MemFree:\s+/,"").slice(0,-3));
    }

    use_memory = (((total_memory_num-free_memory_num)/total_memory_num)*100).toFixed(1).toString()+ " %";

    return use_memory;
  }


  /**
   * Format the version number
   */
  formatVersion(version: number) {
    return moment.unix(version).format('YYYY-MM-DD');
  }

  /**
   * Format screen state
   */
  formatScreenstate(state: boolean) {
    if (state) {
      return "ON";
    } else {
      return "OFF";
    }
  }

  /**
   * Format TVbrand
   */
  formatTVbrand(brand: string) {
    if (brand == "MST") {
      return "Konek.ai";
    } else {
      return brand;
    }
  }

  /**
   * Format tv_model
   */
  formatTVmodel(model: string) {
    if (model == "MStar") {
      return "Konek 50";
    } else {
      return model;
    }
  }

  /**
   * Format ir version
   */
  formatIRversion(version: string, enable: boolean) {
    if (version == "" && enable) {
      return "V1.0";
    } else {
      return version;
    }
  }

  /**
   * Format ir version
   */
  formatHardwareversion(version: number) {
    if (version == 1) {
      return "V1 ou V2 Pico";
    } else if (version == 2){
      return "V2 Moca";
    } else {
      return "Not detected";
    }
  }


}
