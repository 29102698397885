
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import CustomerForm from '@/components/forms/CustomerForm.vue';
import DeviceStatusForm from '@/components/forms/DeviceStatusForm.vue';
import StatusForm from '@/components/forms/StatusForm.vue';
import NoteForm from '@/components/forms/NoteForm.vue';
import CleanupForm from '@/components/forms/CleanupForm.vue';
import ConfigForm from '@/components/forms/ConfigForm.vue';

import { Kokote } from '@/entities/devices/Kokote';
import { Room } from '@/entities/devices/Room';

@Component({
  components: {
    DeviceStatusForm,
    CustomerForm,
    StatusForm,
    NoteForm,
    CleanupForm,
    ConfigForm
  }
})
export default class DeviceListRow extends Vue {
  @Prop({ required: true })
  device!: Kokote;

  save() {
    this.$rooms.edit(this.device!.room as Room, {
      checked_in: this.device.room!.checked_in,
      language: this.device.room!.language,
      customer: this.device.room!.customer,
      message: this.device.room!.message,
      cleanup: this.device.room?.lastCleanup != null ? new Date(this.device.room!.lastCleanup!).getTime() : undefined,
      cleanup_name: this.device.room!.lastCleanupName,
      services_enabled: this.device.room!.services_enabled,
      lock_device: this.device.lock_device,
      unavailable: this.device.room?.not_available != null ? new Date(this.device.room!.not_available!).getTime() : undefined,
      note: this.device.room!.note,
      boot_on_tv: this.device.boot_on_tv,
      tv_channel: this.device.tv_channel
    });
  }
}
