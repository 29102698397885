
import Vue from 'vue'
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import axios from 'axios';

import { Kokote } from '@/entities/devices/Kokote';

@Component
export default class StatusForm extends Vue {
  @Prop({ required: true })
  device!: Kokote;

  apps = false;
  games = false;

  async mounted() {
    this.apps = await this.checkApps('apps');
    this.games = await this.checkApps('games');
  }

  async getAppList(from: string) {
    const response = await axios.get(`/v1/proxy/list?from=${from}`);
    return response.data.split(" ");
  }

  async checkApps(list: string) {
    const appsList: string[] = await this.getAppList(list);
    const apps = this.device.apps_installed.split(" ");
    const valid = appsList.every(app => apps.includes(app));
    
    return valid;
  }
}
