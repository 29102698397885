
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import moment from 'moment';

import DevicesListRow from './DevicesListRow.vue';
import DevicesDial from '@/components/buttons/DevicesDial.vue';

import { DataTableHeader } from 'vuetify';
import { Kokote } from '@/entities/devices/Kokote';
import { Location } from '@/entities/Location';

@Component({
  computed: mapGetters([
    'location'
  ]),

  components: {
    DevicesListRow,
    DevicesDial
  }
})
export default class DevicesList extends Vue {
  @Prop()
  value!: string;

  // Current location
  location!: Location;

  // Devices list
  kokotes: Kokote[] = [];

  // Devices selected
  selected: Kokote[] = [];

  // Devices expanded
  expanded: Kokote[] = [];

  // Refresh timer
  timer: any;

  fabMenu = false;

  headersFull: DataTableHeader[] = [
    {
      text: "Room number",
      value: "room.room_number"
    },
    {
      text: "Serial number",
      value: "serial_number",
      filterable: false,
    },
    {
      text: "Lan IP",
      value: "lan_ip"
    },
    {
      text: "Mac address",
      value: "mac_address",
      filterable: false,
    },
    {
      text: "System version",
      value: "version"
    },
    {
      text: "Last ping",
      value: "updated_at",
      filterable: false
    },
    { text: '', value: 'data-table-expand' },
  ];

  headersLight: DataTableHeader[] = [
    {
      text: "Room number",
      value: "room.room_number",
      width: 130
    },
    {
      text: "Last ping",
      value: "updated_at",
      filterable: false,
      width: 120
    },
    { text: '', value: 'data-table-expand' },
  ];

  async mounted() {
    this.fetchDevices();

    this.timer = setInterval(() => {
      this.fetchDevices();
    }, 30*1000);
  }

  beforeDestroy() {
    clearInterval(this.timer);
  }

  /**
   * Fetch the devices list
   */
  @Watch("location")
  async fetchDevices() {
    this.kokotes = await this.$kokotes.listFromLocation(this.location);
  }

  /**
   * Format the version number
   */
  formatVersion(version: number) {
    return moment.unix(version).format('YYYY-MM-DD');
  }

  /**
   * Format the last ping date
   */
  formatPing(updated_at: Date) {
    return moment(updated_at).fromNow();
  }
  /**
   * Check the last time the kokote was pinged
   */
  checkPing(updated_at: Date) {
    const elapsed = moment().diff(moment.utc(updated_at), 'minutes');

    if (elapsed > 30) return this.$vuetify.theme.dark ? "red darken-4" : "red lighten-4";

    if (elapsed > 2) return this.$vuetify.theme.dark ? "yellow darken-4" : "yellow lighten-4";

    return "";
  }

  /**
   * Get and set the search value
   */
  get search() {
    return this.value;
  }

  set search(value) {
    this.$emit('input', value);
  }
}
