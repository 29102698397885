
import Vue from 'vue'
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { Kokote } from '@/entities/devices/Kokote';

@Component
export default class NoteForm extends Vue {
  @Prop({ required: true })
  device!: Kokote;

  menu = false;
  date = new Date().toISOString().slice(0,10)

  mounted() {
    if (this.device.room?.not_available !== null) {
      this.date = new Date(this.device!.room!.not_available as Date).toISOString().slice(0, 10);
    }
  }

  @Watch('date')
  updateDate() {
    this.device!.room!.not_available = new Date(this.date);
  }

  get formatCleanup() {
    if (this.device?.room?.lastCleanup === null) {
      return "Not available";
    }

    return new Date(this.device!.room!.lastCleanup!).toISOString().slice(0, 10);
  }

  updateCleanup() {
    this.device!.room!.lastCleanup = new Date();
  }
}
