
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { Kokote } from '@/entities/devices/Kokote';

@Component
export default class DeleteDevicesDialog extends Vue {
  @Prop()
  value!: boolean;

  @Prop({ required: true })
  selected!: Kokote[];

  @Prop({ required: true })
  removeDevices!: Function;

  get show() {
    return this.value;
  }

  set show(value) {
    this.$emit('input', value);
  }
}
