
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import ResetDevicesDialog from '@/components/dialogs/ResetDevices.vue';
import DeleteDevicesDialog from '@/components/dialogs/DeleteDevices.vue';
import AlertDevicesDialog from '@/components/dialogs/AlertDevices.vue';
import ShellDevicesDialog from '@/components/dialogs/ShellDevices.vue';

import { Kokote } from '@/entities/devices/Kokote';
import { Room } from '@/entities/devices/Room';

import { mapGetters } from 'vuex';

@Component({
    computed: mapGetters([
    'isAdmin'
  ]),
  components: {
    ResetDevicesDialog,
    DeleteDevicesDialog,
    AlertDevicesDialog,
    ShellDevicesDialog
  }
})
export default class DevicesDial extends Vue {
  @Prop()
  value!: Kokote[];

  show = false;
  deleteDialog = false;
  resetDialog = false;
  alertDialog = false;
  shellDialog = false;
  isAdmin!: boolean;

  /**
   * Copy the IPs of selected devices
   */
  async copy() {
    this.$helpers.copy(this.selected.map(kokote => kokote.lan_ip).join(' '));
  }

  /**
   * Execute a shell command
   */
  async execute(method: string, params: string[]) {
    const devices: string = this.selected.map((device) => device.serial_number).join(',');

    this.$socket.execute({
      devices,
      method,
      params
    });
  }

  /**
   * Delete a device in the database
   */
  async removeDevices() {
    for (const device of this.selected) {
      await this.$kokotes.delete(device);
    }

    this.deleteDialog = false;
    this.selected = [];
    this.$store.dispatch('loadLocations');
  }

  /**
  * Reseta device in the database
  */
  async resetDevices() {
    for (const device of this.selected) {

      if (device.room) {
        this.$rooms.edit(device.room, {
        checked_in: false,
        customer: "N/A",
        custom_media_type: 'None',
        custom_media_url: ''
        })
      }
    }

    this.execute('clearAccounts',[''])
    this.execute('reboot',[''])

    this.resetDialog = false;
    this.selected = [];
    this.$store.dispatch('loadLocations');
  }



  get selected() {
    return this.value;
  }

  set selected(value) {
    this.$emit('input', value);
  }
}
