
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { langs } from '@/constants';
import { Kokote } from '@/entities/devices/Kokote';

@Component
export default class CustomerForm extends Vue {
  @Prop({ required: true })
  device!: Kokote;

  langs = langs;
}
