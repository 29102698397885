
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { Kokote } from '@/entities/devices/Kokote';

@Component
export default class AlertDevicesDialog extends Vue {
  @Prop()
  value!: boolean;

  @Prop({ required: true })
  selected!: Kokote[];

  alertText = "";

  execute() {
    const devices: string = this.selected.map((device) => device.serial_number).join(',');

    this.$socket.execute({
      devices,
      method: 'alert',
      params: [this.alertText]
    }).then(() => {
      this.alertText = "";
      this.show = false;
    });
  }

  get show() {
    return this.value;
  }

  set show(value) {
    this.$emit('input', value);
  }
}
