
import Vue from 'vue'
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { Kokote } from '@/entities/devices/Kokote';

@Component
export default class ConfigForm extends Vue {
  @Prop({ required: true })
  device!: Kokote;

}
